<template>
  <div class="permission_management" >
    <div class="title_area">
      <div class="title_area_left">
        <span class="current_staff">当前员工：{{ this.queryData.name }}</span>
      </div>
      <div class="title_area_right" style="float: right;margin-right: 0;">
        <el-button type="primary"  style="margin-right: 20px;" @click="toAdd()" ><i class="iconfont iconicon-22" ></i>保存</el-button>
        <el-button type="el-button el-button--primary el-button--medium is-plain"  style="margin-right: 20px;" @click="doBack()">取消</el-button>
      </div>
    </div>
  <div class="table">
    <div class="query_area">
      <div  class="query_area_left">
        <div  class="query_btn">
          <el-input
              placeholder="请输入内容"
              v-model="companyName">
            <i slot="suffix" class="el-input__icon el-icon-search" @click="getList()"></i>
          </el-input>
        </div>
        <div class="show_selected_btn">
          <el-checkbox v-model="showSelected" @change="doShowSelected()">仅显示勾选</el-checkbox>
        </div>
      </div>
      <div  class="query_area_right">
        <div class="selected_count">
          已选：<span style="color: #3d9eff">{{selectedCount}}</span>/{{totalCount}}家公司
        </div>
        <div class="batch_change_btn">
          批量调整角色为：
          <el-select v-model="batchChangeRoleManage" placeholder="请批量调整角色" class="settingSelect" @change="doBatchChangeRole">
            <el-option v-for="item in roleList" :key="item.id" :label="item.name" :value="item.id"/>
          </el-select>
          <!-- <el-radio v-for="item in roleList" :key="item.id" :label="item.id" 
            v-model="batchChangeRoleManage" >
            {{item.name}}
          </el-radio> -->
            <!-- <el-checkbox  label="管理员" key="01" v-model="batchChangeRoleManage"  @change="doBatchChangeRole('01')"></el-checkbox>
            <el-checkbox  label="普通用户" key="02" v-model="batchChangeGeneral" @change="doBatchChangeRole('02')"></el-checkbox> -->
        </div>
      </div>
    </div>
    <div class="filter-container" v-loading='isLoading'>
      <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%;height:90%"
          row-key='companyId'
          @select="handleSelection"
          @selection-change="handleSelectionChange"
          @select-all="handleSelectionAll">
        <el-table-column
            type="selection"
            :reserve-selection="true"
            width="55">
        </el-table-column>
        <el-table-column
            prop="companyName"
            label="权限公司"
            >
        </el-table-column>
        <el-table-column
            label="操作"
            width="400">
          <template slot-scope="scope" >
            <el-radio v-for="item in scope.row.roleList" :key="item.id" :label="item.id" @change="rowSelection(scope.row)"
             v-model="scope.row.roleId" :disabled="!scope.row.selected">
             {{item.name}}
            </el-radio>
            <!-- <el-radio v-for="item in roleList" :key="item.id" :label="item.id"
             v-model="scope.row.roleId" :disabled="!scope.row.selected">
             {{item.name}}
            </el-radio> -->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[ 100, 200, 300, 400]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCount"
        class="pagination">
      </el-pagination>
    </div>
  </div>

  </div>
</template>

<script>
  import BooksTable from './modules/booksTable'
  import axios from 'axios';
  export default {
    components: { BooksTable },
    data() {
      return {
        queryData:{},
        totalCount:0,
        selectedCount:0,
        showSelected:false,
        companyName: '',
        batchChangeRoleManage: 1002,  //单选
        // batchChangeGeneral:false,
        // batchChangeRoleList:['管理员','普通用户'],
        tableData: [],
        tableDataTemp:[],
        selectedData:[],
        multipleSelection : [],
        currentPage:1,
        pageSize:100,
        isLoading:false,

        roleList:[],
      }
    },
    methods: {
      handleSizeChange(val) {
        this.pageSize=val;
        this.getList()
      },
      handleCurrentChange(val) {
        this.currentPage=val;
        this.getList();
      },
      toggleSelection(rows) {
        // console.log(rows,'回显选中');
        // this.$refs.multipleTable.clearSelection();
        if (rows) {
          rows.forEach(row => {
            this.$refs.multipleTable.toggleRowSelection(row, true); // 设置选中
          });
        }
      },
      handleSelectionChange(value){
        this.multipleSelection = value;
        this.selectedCount = value.length;
      },
      rowSelection(row){
        this.multipleSelection.map((item,index)=>{
          if(item.companyId == row.companyId){
            this.multipleSelection[index].roleId = row.roleId;
          }
        })
        console.log(this.multipleSelection,row);
      },
      handleSelection(val,row) {
        this.multipleSelection = val;
        this.selectedCount=this.multipleSelection.length;
        this.changeCurrentRow(row);
      },
      handleSelectionAll(val){
        this.multipleSelection = val;
        this.selectedCount=this.multipleSelection.length;
        let _this=this;
        if(val.length){
          for(let i=0;i<_this.tableData.length;i++){
            _this.tableData[i].selected=true;
          }
        }else{
          for(let i=0;i<_this.tableData.length;i++){
            _this.tableData[i].selected=false;
            _this.tableData[i].roleId = 1002;
          }
        }
      },
      changeCurrentRow(selected){
        let _this=this;
        for(var i=0;i<_this.tableData.length;i++){
            if(selected.companyId==_this.tableData[i].companyId){
              if(selected.selected==false){
                _this.tableData[i].selected=true;
                _this.tableData[i].roleId = 1002;
              }else{
                _this.tableData[i].selected=false;
                _this.tableData[i].roleId = 1002;
              }
              break;
            }
        }
      },
      doAdd(status){
        let url = '/api/euser/account/setting'
        let obj = {
          id: this.queryData.id,
          userId: this.getToken('userId'),
          sysCompanyRoleDtos: this.multipleSelection, //当前页勾选处理
        }
        this.isLoading = true;
        axios.post(url, JSON.stringify(obj), {
            headers: {
              'Content-Type': "application/json;charset=utf-8",
              'Authorization': this.getCookies('Authorization'),
              'group' :process.env.VUE_APP_GROUP
            },
          }).then(res => {
            if (res.data.success) {
              if(status == '1'){
                this.isLoading = false;
                this.currentPage = 1 ;
                this.getList();
              }else if(status == '2'){
                einvAlert.success("提示","保存成功");
                this.$router.push("/permission/subAccount");
              }
              // console.log('提交成功');
            } else {
              einvAlert.error("提示",res.msg)
            }
            this.isLoading = false;
          }
        )
      },
      toAdd(){
        this.doAdd('2')
      },
      doBack(){
        this.$router.push("/permission/subAccount");
      },
      doBatchChangeRole(value){
        console.log(value);
        if(this.multipleSelection.length!=0){
          for(var i=0;i<this.multipleSelection.length;i++){
            this.multipleSelection[i].roleId = value;
            // newMultipleSelection.push(row);
          }
            // var newMultipleSelection=[];
          // this.multipleSelection = newMultipleSelection;
        }
        this.getList();
      },
      getList(){
        this.isLoading = true;
        let url = '/api/euser/account/permission'
        let obj = {
          id: this.queryData.id,
          showSelected:this.showSelected,
          page:this.currentPage,
          rows:this.pageSize,
          companyName:this.companyName,
          sysCompanyRoleDtos: this.multipleSelection, //当前页勾选处理
        }
        axios.post(url, JSON.stringify(obj), {
            headers: {
              'Content-Type': "application/json;charset=utf-8",
              'Authorization': this.getCookies('Authorization'),
              'group' :process.env.VUE_APP_GROUP
            },
          }).then(res => {
            if (res.data.success) {
                let data = res.data.data.map(item=>{
                  return {
                    ...item,
                    roleId: item.roleId * 1
                  }
                });
                this.totalCount = res.data.totalCount;
                this.tableData = data;
                this.tableDataTemp = data;
            } else {
              einvAlert.error("提示",res.msg)
            }
            this.isLoading = false;
          }
        )
      },
      doShowSelected(){
        this.currentPage = 1;
        this.getList();
      },

      initRoleList(){
        this.$store.dispatch("FindRoleList", {
          userId: this.getToken("userId"),
          companyId: this.getToken("companyId"),
        }).then(res => {
          if (res.success) {
              res.data.map((item)=>{
                if(!item.canDelete){
                  this.roleList.push(item)
                }
              })
          } else {
            einvAlert.error("提示",res.msg)
          }
        }).catch(err => {
          // einvAlert.error("提示",err)
          console.dir(err)
        })
      },

      getSelectedList(){
        let _this = this;
        _this.isLoading = true;
        this.$store.dispatch("GetSelectedPermission", {
          id: _this.queryData.id,
        }).then(res => {
            if (res.success) {
              _this.multipleSelection = res.data;
              _this.selectedCount = res.data.length;
              var newMultipleSelection=[];
              for(let i=0;i<res.data.length;i++){
                var row = res.data[i];
                if(row.selected){
                  row.selected = true;
                  row.roleId = row.roleId * 1;
                  newMultipleSelection.push(row);
                }else{
                  row.selected = false;
                  row.roleId = 1002;
                }
              }
              console.log(this.multipleSelection,'初始选中数据');
              this.$nextTick(() => {
                this.$refs.multipleTable.clearSelection();
                setTimeout(() => {
                  _this.toggleSelection(newMultipleSelection);
                  this.getList();
                }, 500);
              })
          } else {
            einvAlert.error("提示",res.msg)
          }
          this.isLoading = false;
        }).catch(err => {
          // einvAlert.error("提示",err)
          console.dir(err)
        })
      }
    },
    mounted() {
      this.initRoleList();
      this.queryData = JSON.parse(this.$route.query.row)
      this.getList();
      this.getSelectedList();
    }
  }
</script>

<style lang='less' scoped>
.permission_management{
  width: 100%;
  height: 95%;
  .title_area{
    width: 100%;
    padding: 1%;
    background: #fff;
    margin-bottom: 1%;
    .title_area_left{
      font-size: 18px;
      color:#333333;
      display: inline-block;
      .current_staff{
        margin-right: 40px;
      }
    }
    .title_area_right{
      display: inline-block;
      position: relative;
      bottom: 6px;
      .iconicon-22{
        font-size: 12px;
        margin-right: 8px;
      }
    }
  }
  .table{
    width: 100%;
    padding: 1% 1% 2% 1%;
    background: #fff;
    height: 90%;
    .query_area{
      font-size: 18px;
      color: #333333;
      .query_area_left{
        display: inline-block;
        .query_btn{
          width: 260px;
          display: inline-block;
          margin-right: 30px;
        }
        .show_selected_btn{
          display: inline-block;
          width: 200px;
        }
      }
      .query_area_right{
        display: inline-block;
        float: right;
        margin-right: 20px;
        .selected_count{
          display: inline-block;
          margin-right: 34px;
        }
        .batch_change_btn{
          display: inline-block;
        }
      }
    }
  }
  .pagination{
    text-align: right;
    margin-top: 20px;
    margin-right: 20px;
  }

}

.filter-container {
  padding: 1.04rem 0;
  height: 100%;
  // height: calc(100% - 90px);
}
</style>
<style lang='less' >
.permission_management{
  .query_area_left{
    .query_btn{
      .el-icon-search:hover{
        cursor:pointer;
      }
    }
  }


}

</style>
